<template>
    <div>

    </div>
</template>

<script>

import api from '@/api/api'

export default {
    name: 'CikisYap',
    data() {
        return {

        }
    },
    mounted() {
        api.User = {}
        localStorage.removeItem('yonetimPosPanel')
        this.$router.push('/giris')
    }
}
</script>